import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'

import personalImg from '../../images/train/personal.jpg';

export default function PersonalTraining() {
    const sliderImages = [
        personalImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Specialized Training" backLink="/specialized-training">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Personal Training</h2>
                                        <h3 className={pageStyles.subtitle}>TRAIN TO MOVE</h3>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            One on one, or in up to groups of 5, you can choose from any of our available classes, scheduled at your convenience, and at the pace and intensity level you require. We suggest that you pick a group of family or friends at more or less the same fitness level if you want to maximize this training function. 
                                            <br /><br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
